import React, { useMemo, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const ReturnTo = {
  aviatorBot: "AVIATOR_BOT",
  ukrainePkmBot: "UKRAINE_PKM_BOT",
  none: "NONE",
};

const AdminCard = ({
  name,
  email,
  restorePasswordHash,
  roles = [],
  projects = [],
  onCopy,
  onDelete,
  onResetPassword,
  onEdit,
  hideActions = false,
}) => {
  const [returnTo, setReturnTo] = useState(ReturnTo.none);

  const [copiedAlertOpen, setCopiedAlertOpen] = useState(false);

  const onChangeReturnTo = (event) => {
    setReturnTo(event.target.value);
  };

  const restoreLink = useMemo(() => {
    let base = `https://admin.axioma.monster/restore?hash=${restorePasswordHash}`;
    switch (returnTo) {
      case ReturnTo.aviatorBot: {
        return `${base}&returnTo=$$$https://aviatorbot.axioma.monster$$$`;
      }
      case ReturnTo.ukrainePkmBot: {
        return `${base}&returnTo=$$$https://ukrainepkm.axioma.monster$$$`;
      }
      default: {
        return base;
      }
    }
  }, [returnTo]);

  const onCopyRestoreLink = () => {
    navigator.clipboard
      .writeText(restoreLink)
      .then(() => setCopiedAlertOpen(true));
  };

  return (
    <Card
      variant="outlined"
      sx={{
        padding: "10px",
        backgroundColor: "grey.900",
      }}
    >
      <Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          alignItems={{ sm: "initial", md: "center" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          gap="10px"
        >
          <Stack
            sx={{ height: "100%" }}
            direction="row"
            alignItems="center"
            gap="10px"
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              {name}
            </Typography>
            <Typography>|</Typography>
            {roles?.map((role) => (
              <Typography
                key={role}
                sx={{
                  fontWeight: "600",
                }}
                color="secondary"
              >
                {role}
              </Typography>
            ))}
          </Stack>
          {!hideActions && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Button onClick={onCopy}>Copy</Button>
              <Button onClick={onEdit}>Edit</Button>
              <Button color="error" onClick={onResetPassword}>
                Reset
              </Button>
              <Button color="error" onClick={onDelete}>
                Delete
              </Button>
            </Stack>
          )}
        </Stack>

        <Divider />

        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          sx={{
            padding: "5px",
            rowGap: "15px",
          }}
        >
          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              Name
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {name}
            </Typography>
          </Grid>

          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              Email
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {email}
            </Typography>
          </Grid>

          {restorePasswordHash && (
            <>
              <Grid xs={12} sm={2}>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
                >
                  Restore password
                </Typography>
              </Grid>

              <Grid xs={12} sm={9}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Return to
                  </FormLabel>
                  <RadioGroup row value={returnTo} onChange={onChangeReturnTo}>
                    <FormControlLabel
                      value={ReturnTo.aviatorBot}
                      control={<Radio />}
                      label="Aviator bot"
                    />
                    <FormControlLabel
                      value={ReturnTo.ukrainePkmBot}
                      control={<Radio />}
                      label="Ukraine PKM bot"
                    />
                    <FormControlLabel
                      value={ReturnTo.none}
                      control={<Radio />}
                      label="None"
                    />
                  </RadioGroup>
                </FormControl>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      ".copyIcon": {
                        opacity: 1,
                      },
                    },
                  }}
                  onClick={onCopyRestoreLink}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "orange",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {restoreLink}
                  </Typography>
                  <ContentCopyRoundedIcon
                    className="copyIcon"
                    color="primary"
                    sx={{
                      fontSize: "24px",
                      ml: "5px",
                      borderRadius: "50%",
                      padding: "3px",
                      transition: ".3s all ease",
                      opacity: 0,
                      ":hover": {
                        backgroundColor: "grey.700",
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </>
          )}

          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              Role
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            {roles.map((role) => (
              <Chip key={role} label={role} />
            ))}
          </Grid>

          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              Projects
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            <Stack direction="row" gap="5px">
              {projects.map((project) => (
                <Chip key={project} label={project} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Snackbar
        open={copiedAlertOpen}
        autoHideDuration={6000}
        severity="success"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }

          setCopiedAlertOpen(false);
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setCopiedAlertOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Copied!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default AdminCard;
