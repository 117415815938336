import axios from "./axios";

const api = {
  async getUsersDb() {
    const response = await axios.get(`/statistics/users-db`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "users-db.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  },

  async getAdmin() {
    const { data } = await axios.get("/admins/me");

    return data;
  },

  async getAllAdmins() {
    const { data } = await axios.get("/admins/all");

    return data;
  },

  async createAdmin(adminData) {
    const { data } = await axios.post("/admins/create", adminData);

    return data;
  },

  async editAdmin(id, editData) {
    const { data } = await axios.patch(`/admins/edit/${id}`, editData);

    return data;
  },

  async deleteAdmin(id) {
    const { data } = await axios.delete(`/admins/delete/${id}`);

    return data;
  },

  async login(loginData) {
    const { data } = await axios.post("/admins/login", loginData);

    return data;
  },

  async restore(restoreData) {
    const { data } = await axios.post("/admins/restore", restoreData);

    return data;
  },

  async resetPassword(id) {
    const { data } = await axios.delete(`/admins/reset/${id}`);

    return data;
  },

  async logout() {
    const { data } = await axios.delete("/admins/logout");

    return data;
  },
};
export default api;
