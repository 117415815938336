import Roles from "./Roles";

const ACCESSES = {
  createAdmin: [Roles.superAdmin, Roles.admin],
  editAdmin: [Roles.superAdmin, Roles.admin],
  deleteAdmin: [Roles.superAdmin, Roles.admin],
  listAdmins: [Roles.superAdmin, Roles.admin],
};

export default ACCESSES;
