import LoggedInRoutes from "./Routes";
import Layout from "./Layout";
import React, { useContext } from "react";
import { AppContext } from "./Context/ContextProvider";
import LoginPage from "./pages/Login/LoginPage";
import AppLoading from "./components/Common/AppLoading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Navigate, Route, Routes } from "react-router-dom";
import RestorePage from "./pages/Restore/RestorePage";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const WithTheme = ({ children }) => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

function App() {
  const { admin, appLoading, notAuthorized } = useContext(AppContext);

  if (appLoading)
    return (
      <WithTheme>
        <AppLoading />
      </WithTheme>
    );

  if (!admin)
    return (
      <WithTheme>
        <Routes>
          <Route path="/restore" element={<RestorePage />} />
          <Route path="/login" element={<LoginPage />} />
          {notAuthorized && (
            <Route path="/*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </WithTheme>
    );

  return (
    <WithTheme>
      <Layout>
        <LoggedInRoutes />
      </Layout>
    </WithTheme>
  );
}

export default App;
