import React, { useContext, useMemo, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import Logo from "./Logo";
import { AppContext } from "../../Context/ContextProvider";
import Avatar from "../Common/Avatar";

const locations = ["/admins", "/account"];

const LinkTab = (props) => {
  return <Tab component={Link} {...props} />;
};

const NavTabs = ({ activeTab }) => {
  return (
    <Stack
      sx={{
        ml: "40px",
        display: { xs: "none", md: "block" },
      }}
    >
      <Tabs value={activeTab}>
        {locations.map((location) => (
          <LinkTab key={location} label={location.substring(1)} to={location} />
        ))}
        {/*<LinkTab label="Admins" to="/admins"/>*/}
        {/*<LinkTab label="Account" to="/account"/>*/}
      </Tabs>
    </Stack>
  );
};

const Navigation = () => {
  const { admin, logout } = useContext(AppContext);
  const location = useLocation();

  const path = useMemo(() => location.pathname, [location.pathname]);
  const activeTab = useMemo(
    () => locations.findIndex((el) => el === path),
    [path]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const menuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: "fixed",
        top: "0",
        width: "100%",
        backgroundColor: "black",
        padding: "5px 10px",
        zIndex: "1",
      }}
    >
      <Logo />
      <NavTabs activeTab={activeTab} />
      <Stack sx={{ display: { xs: "block", md: "none" } }}>
        <Button onClick={menuOpen}>{location.pathname.substring(1)}</Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={menuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {locations.map((location) => (
            <MenuItem key={location} onClick={menuClose}>
              <NavLink
                style={{
                  textDecoration: "none",
                  color: "#CBFB45",
                  textTransform: "capitalize",
                }}
                to={location}
              >
                {location.substring(1)}
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <Avatar name={admin.name ?? ""} logout={logout} />
    </Stack>
  );
};

export default Navigation;
