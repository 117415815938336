import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import bgImg from "./images/bg.png";
import { AppContext } from "../../Context/ContextProvider";
import { useLocation } from "react-router-dom";

const RestorePage = () => {
  const { restorePassword, loginLoading, loginError } = useContext(AppContext);
  const search = useLocation().search;
  const returnTo = search.split("$$$")[1];

  const [hash, setHash] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(search);
    setHash(params.get("hash"));
  }, [search]);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const disabled = useMemo(
    () =>
      Boolean(password.length < 8 || password2 !== password || loginLoading),
    [password, password2, loginLoading]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    restorePassword(
      {
        hash,
        password,
      },
      returnTo
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: `url("${bgImg}")`,
      }}
    >
      <form
        onSubmit={onSubmit}
        style={{
          width: "100%",
        }}
      >
        <Stack
          sx={{
            width: { xs: "90%", md: "30%" },
            maxWidth: "405px",
            margin: "0 auto",
            backgroundColor: "grey.900",
            borderRadius: "12px",
            padding: "20px",
            gap: "15px",
            border: loginError && "1px solid red",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
            }}
          >
            Create new password
            {loginError && (
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                error
              </span>
            )}
          </Typography>
          <TextField
            variant="filled"
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="filled"
            label="Repeat password"
            type="password"
            onChange={(e) => setPassword2(e.target.value)}
          />
          <Button color="success" type="submit" disabled={disabled}>
            Restore
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default RestorePage;
