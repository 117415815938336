import React from "react";
import { Route, Routes } from "react-router-dom";
import Admins from "./pages/Admins";
import NavigateToDefault from "./NavigateToDefault";
import ProtectedRoute from "./ProtectedRoute";
import ACCESSES from "./constants/Accesses";
import Account from "./pages/Account";

const RoutesContainer = () => {
  return (
    <Routes>
      <Route
        path="/admins"
        element={
          <ProtectedRoute allowedRoles={ACCESSES.listAdmins}>
            <Admins />
          </ProtectedRoute>
        }
      />
      <Route path="/account" element={<Account />} />
      <Route path="/*" element={<NavigateToDefault />} />
    </Routes>
  );
};

export default RoutesContainer;
