import React, { useContext, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import bgImg from "./images/bg.png";
import { AppContext } from "../../Context/ContextProvider";
import { useLocation } from "react-router-dom";

const LoginPage = () => {
  const { login, loginLoading, loginError } = useContext(AppContext);
  const search = useLocation().search;
  const returnTo = search.split("$$$")[1];

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    login(
      {
        email,
        password,
      },
      returnTo
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: `url("${bgImg}")`,
      }}
    >
      <form
        onSubmit={onSubmit}
        style={{
          width: "100%",
        }}
      >
        <Stack
          sx={{
            width: { xs: "90%", md: "30%" },
            maxWidth: "405px",
            margin: "0 auto",
            backgroundColor: "grey.900",
            borderRadius: "12px",
            padding: "20px",
            gap: "15px",
            border: loginError && "1px solid red",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "24px" },
            }}
          >
            Login
            {loginError && (
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                error
              </span>
            )}
          </Typography>
          <TextField
            variant="filled"
            label="Email"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="filled"
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            color="success"
            type="submit"
            disabled={loginLoading || !email || !password}
          >
            login
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default LoginPage;
