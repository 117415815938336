import React, { createContext, useEffect, useState } from "react";
import api from "../api";

export const AppContext = createContext({
  admin: null,
  notAuthorized: false,
  appLoading: true,
  loginLoading: true,
  loginError: true,
  login: (data) => {},
  restorePassword: (data) => {},
  resetPassword: (id) => {},
  logout: () => {},
  admins: [],
  adminsLoading: true,
  getAdmins: () => {},
  createAdmin: (data) => {},
  editAdmin: (id, data) => {},
  deleteAdmin: (id) => {},
});

const ContextProvider = ({ children }) => {
  const [appLoading, setAppLoading] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);

  const getAdmin = () => {
    setAppLoading(true);
    api
      .getAdmin()
      .then((me) => setAdmin(me))
      .catch(() => {
        setNotAuthorized(true);
        setAdmin(null);
      })
      .finally(() => setAppLoading(false));
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const login = ({ email, password }, returnTo = "") => {
    setLoginLoading(true);
    api
      .login({
        email,
        password,
      })
      .then((admin) => {
        setLoginError(false);
        if (returnTo) {
          window.document.location = returnTo;
        } else {
          setNotAuthorized(false);
          setAdmin(admin);
        }
      })
      .catch(() => setLoginError(true))
      .finally(() => setLoginLoading(false));
  };

  const logout = () => {
    api.logout().then(() => {
      setAdmin(null);
      setNotAuthorized(true);
    });
  };

  const restorePassword = ({ hash, password }, returnTo = "") => {
    setLoginLoading(true);
    api
      .restore({
        hash,
        password,
      })
      .then((admin) => {
        setLoginError(false);
        if (returnTo) {
          window.document.location = returnTo;
        } else {
          setNotAuthorized(false);
          setAdmin(admin);
        }
      })
      .catch(() => setLoginError(true))
      .finally(() => setLoginLoading(false));
  };

  const [admins, setAdmins] = useState([]);
  const [adminsLoading, setAdminsLoading] = useState(false);

  const getAdmins = () => {
    setAdminsLoading(true);
    api
      .getAllAdmins()
      .then((items) => setAdmins(items))
      .finally(() => setAdminsLoading(false));
  };

  const createAdmin = (data) => {
    api.createAdmin(data).then(() => {
      getAdmins();
    });
  };

  const editAdmin = (id, editData) => {
    api.editAdmin(id, editData).then(() => {
      getAdmins();
    });
  };

  const resetPassword = (id) => {
    api.resetPassword(id).then(() => {
      getAdmins();
    });
  };

  const deleteAdmin = (id) => {
    api.deleteAdmin(id).then(() => {
      getAdmins();
    });
  };

  return (
    <AppContext.Provider
      value={{
        admin,
        notAuthorized,
        appLoading,
        loginLoading,
        loginError,
        login,
        logout,
        restorePassword,
        resetPassword,
        admins,
        adminsLoading,
        getAdmins,
        createAdmin,
        editAdmin,
        deleteAdmin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
