import { useMemo } from "react";

const useIsEdited = (entity, state, skip = [], forceEdited = false) => {
  return useMemo(() => {
    if (!entity) return true;

    for (let key in entity) {
      if (skip.includes(key)) continue;

      if (entity[key] !== state[key]) {
        return true;
      }
    }

    return forceEdited;
  }, [entity, state, forceEdited]);
};

export default useIsEdited;
