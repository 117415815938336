import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useIsEdited from "../../hooks/useIsEdited";
import { ROLES } from "../../constants/Roles";
import { PROJECTS } from "../../constants/Projects";

const AdminModal = ({
  isOpen,
  onClose,
  onSubmit,
  defaultState,
  title = "Edit Modal",
}) => {
  const [state, setState] = useState(null);
  const [forceEdited, setForceEdited] = useState(false);

  useEffect(() => {
    setState({ ...defaultState });
  }, [defaultState]);

  useEffect(() => {
    setForceEdited(state?.roles !== defaultState?.roles);
  }, [state?.roles]);

  const edited = useIsEdited(
    defaultState,
    state,
    ["_id", "roles"],
    forceEdited
  );

  const valid = useMemo(() => {
    if (!state) return false;
    return (
      Boolean(state.name) &&
      Boolean(state.email) &&
      Boolean(state.roles?.length)
    );
  }, [state]);

  const handleChange = (prop, value) => {
    setState((prev) => ({ ...prev, [prop]: value }));
  };

  const toggleProject = (project) => {
    setState((prev) => ({
      ...prev,
      projects: prev?.projects?.includes(project)
        ? prev?.projects?.filter((item) => item !== project)
        : [...prev?.projects, project],
    }));
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate(-50%)",
          backgroundColor: "grey.900",
          padding: { xs: "0 8px 0", md: "0 20px 0" },
          borderRadius: "8px",
          width: { xs: "96%", md: "50%" },
          maxHeight: "80%",
          overflowY: "scroll",
        }}
      >
        <Stack
          sx={{
            position: "sticky",
            top: "0",
            width: "100%",
            backgroundColor: "grey.900",
            zIndex: "1",
            padding: "10px 0",
          }}
        >
          <Typography>{title}</Typography>
          <Divider />
        </Stack>

        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "initial", sm: "center" }}
          sx={{
            padding: "5px",
            rowGap: "15px",
          }}
        >
          <Grid xs={12} sm={2}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              Name
            </Typography>
          </Grid>
          <Grid xs={12} sm={10}>
            <TextField
              sx={{
                width: "100%",
              }}
              inputProps={{
                sx: {
                  paddingY: "10px",
                },
              }}
              value={state?.name}
              onChange={(e) => {
                handleChange("name", e.target.value);
              }}
              placeholder="Name"
            />
          </Grid>

          <Grid xs={12} sm={2}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              Email
            </Typography>
          </Grid>
          <Grid xs={12} sm={10}>
            <Stack gap="10px" direction="row" justifyContent="space-between">
              <TextField
                sx={{
                  width: "100%",
                }}
                inputProps={{
                  sx: {
                    fontSize: "14px",
                    width: "100%",
                    paddingY: "10px",
                  },
                }}
                value={state?.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                placeholder="Email"
              />
            </Stack>
          </Grid>

          <Grid xs={12} sm={2}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              Role
            </Typography>
          </Grid>

          <Grid xs={12} sm={10}>
            <Stack direction="row" alignItems="center" gap="5px">
              {ROLES.map((role) => (
                <Chip
                  key={role}
                  label={role}
                  variant={state?.roles?.includes(role) ? "filled" : "outlined"}
                  onClick={() => {
                    handleChange("roles", [role]);
                  }}
                />
              ))}
            </Stack>
          </Grid>
          <Grid xs={12} sm={2}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              Projects
            </Typography>
          </Grid>

          <Grid xs={12} sm={10}>
            <Stack direction="row" alignItems="center" gap="5px">
              {PROJECTS.map((project) => (
                <Chip
                  key={project}
                  label={project}
                  variant={
                    state?.projects?.includes(project) ? "filled" : "outlined"
                  }
                  onClick={() => {
                    toggleProject(project);
                  }}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: "20px",
            ml: "auto",
            position: "sticky",
            bottom: "0",
            width: "100%",
            backgroundColor: "grey.900",
            zIndex: "1",
            padding: "10px 0",
          }}
        >
          <Button sx={{ ml: "auto" }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!edited || !valid}
            sx={{
              ml: "10px",
            }}
            variant="outlined"
            color="success"
            onClick={() => {
              onSubmit(state);
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AdminModal;
