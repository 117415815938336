import React from "react";
import { LinearProgress, Stack } from "@mui/material";

const AppLoading = () => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <LinearProgress />
      <LinearProgress color="secondary" />
      <LinearProgress color="warning" />
      <LinearProgress color="error" />
      <LinearProgress color="success" />
      <LinearProgress />
    </Stack>
  );
};

export default AppLoading;
