const Roles = {
  superAdmin: "SUPER_ADMIN",
  admin: "ADMIN",
  manager: "MANAGER",
  moderator: "MODERATOR",
};

export const ROLES = [Roles.admin, Roles.manager, Roles.moderator];

export default Roles;
