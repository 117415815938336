import React, { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "./Context/ContextProvider";
import Roles from "./constants/Roles";

const NavigateToDefault = () => {
  const { admin } = useContext(AppContext);

  const defaultLocation = useMemo(() => {
    if (!admin) return "/login";

    const roles = admin?.roles;

    if (roles.includes(Roles.superAdmin)) return "/admins";
    if (roles.includes(Roles.admin)) return "/admins";
    if (roles.includes(Roles.manager)) return "/account";
    if (roles.includes(Roles.moderator)) return "/account";

    return "/login";
  }, [admin]);
  return <Navigate to={defaultLocation} />;
};

export default NavigateToDefault;
