import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
} from "@mui/material";
import { AppContext } from "../Context/ContextProvider";
import AdminCard from "../components/Admins/AdminCard";
import AdminModal from "../components/Admins/AdminModal";

const Admins = () => {
  const {
    admin: me,
    admins,
    adminsLoading,
    createAdmin,
    editAdmin,
    deleteAdmin,
    resetPassword,
    getAdmins,
  } = useContext(AppContext);

  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [creatingNewAdmin, setCreatingNewAdmin] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);

  useEffect(() => {
    getAdmins();
  }, []);

  const onCreateNewAdmin = () => {
    setSelectedAdmin({
      name: "",
      email: "",
      roles: [],
      projects: [],
    });
    setCreatingNewAdmin(true);
  };

  const onSubmit = (data) => {
    if (creatingNewAdmin) {
      createAdmin(data);
    } else {
      editAdmin(selectedAdmin._id, data);
    }

    setSelectedAdmin(null);
    setCreatingNewAdmin(false);
  };

  if (adminsLoading)
    return <LinearProgress sx={{ mt: "5px" }} color="secondary" />;

  return (
    <Stack
      sx={{
        gap: "35px",
        padding: { xs: "0", md: "10px 30px" },
      }}
    >
      <Button
        sx={{
          mt: "10px",
        }}
        variant="contained"
        color="success"
        onClick={onCreateNewAdmin}
      >
        Create new admin
      </Button>

      {admins?.map((admin) => (
        <AdminCard
          key={admin._id}
          hideActions={admin._id === me._id}
          name={admin.name}
          email={admin.email}
          restorePasswordHash={admin.restorePasswordHash}
          roles={admin.roles ?? []}
          projects={admin.projects ?? []}
          onCopy={() => {
            const newAdmin = { ...admin, email: "" };
            delete newAdmin._id;

            setSelectedAdmin(newAdmin);
            setCreatingNewAdmin(true);
          }}
          onEdit={() => {
            setSelectedAdmin(admin);
          }}
          onDelete={() => {
            setAdminToDelete(admin);
          }}
          onResetPassword={() => {
            resetPassword(admin._id);
          }}
        />
      ))}

      <AdminModal
        title={creatingNewAdmin ? "Create new admin" : "Edit admin"}
        isOpen={Boolean(selectedAdmin)}
        onClose={() => {
          setSelectedAdmin(null);
          setCreatingNewAdmin(false);
        }}
        defaultState={selectedAdmin}
        onSubmit={onSubmit}
      />

      <Dialog
        open={Boolean(adminToDelete)}
        onClose={() => setAdminToDelete(null)}
      >
        <DialogTitle>Want to delete admin?</DialogTitle>
        <DialogContent>
          <DialogContentText>{adminToDelete?.name}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAdminToDelete(null)} autoFocus>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              deleteAdmin(adminToDelete?._id);
              setAdminToDelete(null);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Admins;
