import React from "react";
import { Box } from "@mui/material";
import Navigation from "./components/Navigation";

const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      <Box
        sx={{
          minHeight: "100vh",
          padding: "75px 10px 0",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
