const Projects = {
  // offers: "OFFERS",
  // leads: "LEADS",
  aviator: "AVIATOR",
  ukrainepkm: "UKRAINEPKM",
  brazil: "BRAZIL",
  dev: "DEV",
  football: "FOOTBALL",
  bullRunRu: "BULL RUN RU",
  bullRunEn: "BULL RUN EN",
  india: "INDIA",
};

export const PROJECTS = Object.values(Projects);

export default Projects;
